import React, { useEffect } from 'react';

const RedirectOnKeyPress: React.FC = () => {
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'A' || event.key === 'a') {
                // Redirect to another link
                window.location.href = 'https://cal.com/agency-astriq/discovery-call'; // Change this URL to your desired destination
            }
        };

        // Add event listener for keydown
        document.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default RedirectOnKeyPress;
