// src/Pages/PrivacyPolicy.tsx
import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl w-full space-y-8">
        <div>
          <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Privacy Policy
          </h1>
        </div>
        <div className="mt-8 bg-white p-8 shadow rounded-lg">
          <p className="text-gray-600 text-justify">
            At AstriQ, we value your privacy and are committed to protecting the
            personal information you share with us. Our website collects only the
            data necessary to provide services like Meta and Google Ads management,
            web development, and design solutions. We use this information to
            improve your experience, respond to inquiries, and deliver tailored
            services. Your data is never sold or shared with unauthorized parties.
            By using our website, you consent to the collection and use of your
            information as outlined in our practices. For any questions or
            concerns, feel free to contact us at{" "}
            <a
              href="mailto:aster@astriq.co"
              className="text-blue-600 hover:text-blue-800"
            >
              aster@astriq.co
            </a>
            {" / "}
            <a
              href="mailto:kumarprateekranjan@gmail.com"
              className="text-blue-600 hover:text-blue-800"
            >
              kumarprateekranjan@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
